<header>
  <a href='https://www.privatetechnetwork.com'>Private Tech Network</a>
  <!--<a [routerLink]="['/ptn']" routerLinkActive="active">Private Tech Network</a>-->
  <a [routerLink]="['/cvc']" routerLinkActive="active">Classic Venture Capital</a>
  <a [routerLink]="['/de']" routerLinkActive="active">Digital Equity ICO, IEO, STO</a>
  <a [routerLink]="['/svc']" routerLinkActive="active">Synthetic VC©</a>
</header>
<router-outlet></router-outlet>
<footer>
  <div class="footer-container">
    <img src='/assets/images/logo.png'>
    <span>All rights reserved @ {{year}}</span>
  </div>
</footer>