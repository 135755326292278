import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CvcComponent } from './cvc/cvc.component';
import { DeComponent } from './de/de.component';
import { PtnComponent } from './ptn/ptn.component';
import { SvcComponent } from './svc/svc.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'ptn',
    pathMatch: 'full'
  },
  {
    path: 'ptn',
    component: PtnComponent
  },
  {
    path: 'cvc',
    component: CvcComponent
  },
  {
    path: 'de',
    component: DeComponent
  },
  {
    path: 'svc',
    component: SvcComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
