import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PtnComponent } from './ptn/ptn.component';
import { CvcComponent } from './cvc/cvc.component';
import { DeComponent } from './de/de.component';
import { SvcComponent } from './svc/svc.component';

@NgModule({
  declarations: [
    AppComponent,
    PtnComponent,
    CvcComponent,
    DeComponent,
    SvcComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
