import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'svc-svc',
  templateUrl: './svc.component.html',
  styleUrls: ['./svc.component.scss']
})
export class SvcComponent implements OnInit {

  private readonly ratioImg = 0.386; // Height devided on Width
  coordinates: string;

  constructor() { }

  private calcCoordinatesImageMap() {
    let imageWidth = document.querySelector('#svcImg').clientWidth;
    let imageHeight = document.querySelector('#svcImg').clientHeight;
    
    let deltaX = imageHeight / imageWidth < this.ratioImg ? (imageWidth - imageHeight / this.ratioImg) / 2 : 0;
    let deltaY = imageHeight / imageWidth > this.ratioImg ? (imageHeight - imageWidth * this.ratioImg) / 2 : 0;
    if (deltaX > 0) {
      imageWidth = imageHeight / this.ratioImg;
    }
    if (deltaY > 0) {
      imageHeight = imageWidth * this.ratioImg;
    }

    this.coordinates = `${imageWidth * 0.2 + deltaX},${imageHeight * 0.516 + deltaY},${imageWidth * 0.38 + deltaX},${imageHeight * 0.645 + deltaY}`;
    //console.log(this.coordinates);
  }

  ngOnInit(): void {
    this.calcCoordinatesImageMap();
  }

  onResize(event) {
    this.calcCoordinatesImageMap();
  }
}
